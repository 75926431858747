import commonAuthRouter from "@/router/common-auth-router";

const router = {
    path: '/store-lake',
    name: 'store-lake',
    component: () => import('@/views/storeLake/Index.vue'),
    redirect: '/store-lake/auth',
    children: [
        {
            path: 'auth',
            name: 'auth',
            component: () => import('@/views/storeLake/Auth.vue'),
            redirect: '/store-lake/auth/login',
            children: commonAuthRouter
        },
        {
            path: 'home',
            name: 'home',
            component: () => import('@/views/storeLake/Home.vue'),
            redirect: '/store-lake/home/list',
            children: [
                {
                    path: 'list',
                    name: 'list',
                    component: () => import('@/views/storeLake/list/Index.vue'),
                    redirect: '/store-lake/home/list/item',
                    children: [
                        {
                            path: 'item',
                            name: 'item',
                            meta: {
                                title: 'home'
                            },
                            component: () => import('@/views/storeLake/list/List.vue'),
                        },
                        {
                            path: 'reader',
                            name: 'reader',
                            component: () => import('@/views/storeLake/list/reader/Reader.vue'),
                            children: [
                                {
                                    path: 'password-book',
                                    name: 'password-book',
                                    component: () => import('@/views/storeLake/list/reader/PasswordBookReader.vue'),
                                },
                                {
                                    path: 'note',
                                    name: 'note',
                                    component: () => import('@/views/storeLake/list/reader/NoteReader.vue'),
                                },
                                {
                                    path: 'link',
                                    name: 'link',
                                    component: () => import('@/views/storeLake/list/reader/LinkReader.vue'),
                                },
                                {
                                    path: 'file',
                                    name: 'file',
                                    component: () => import('@/views/storeLake/list/reader/FileReader.vue'),
                                }
                            ]
                        },
                        {
                            path: 'verify',
                            name: 'mine',
                            component: () => import('@/views/storeLake/list/Verify.vue'),
                        },
                        {
                            path: 'new-item',
                            name: 'new-item',
                            component: () => import('@/views/storeLake/list/NewItem.vue'),
                        },
                        {
                            path: 'setting',
                            name: 'setting',
                            component: () => import('@/views/storeLake/list/Setting.vue'),
                        },
                    ]
                },
                {
                    path: 'mine',
                    name: 'mine',
                    component: () => import('@/views/storeLake/mine/Index.vue'),
                    redirect: '/store-lake/home/mine/list',
                    children: [
                        {
                            path: 'list',
                            name: 'list',
                            component: () => import('@/views/storeLake/mine/List.vue'),
                        },
                        {
                            path: 'safe-password',
                            name: 'safe-password',
                            component: () => import('@/views/storeLake/mine/SafePassword.vue'),
                        },
                    ]
                },
            ]
        },

    ]
}
export default router