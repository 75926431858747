import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/css/common.css';
import axios from 'axios'
import qs from 'qs'
import Vant from 'vant';
import 'vant/lib/index.css';
//import {saveAs} from 'file-saver';

Vue.use(Vant);
Vue.use(ElementUI);

Vue.config.productionTip = false
Vue.prototype.$axios = axios

const app = new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

const localBaseURL = 'http://localhost:8000'
const remoteBaseURL = window.location.protocol + '//' + window.location.host

axios.defaults.baseURL = `${remoteBaseURL}`
axios.defaults.crossDomain = true
axios.interceptors.request.use(
    config => {
        const token = window.localStorage.getItem('token')
        const tmpSafeCode = window.localStorage.getItem('tmpSafeCode')
        if (config.method === 'post' || config.method === 'delete') {
            if (token) {
                config.data['token'] = token
            }
            if (tmpSafeCode) {
                config.data['tmpSafeCode'] = tmpSafeCode
            }
            config.data = qs.stringify(config.data)
        } else if (config.method === 'get') {
            if (config.params == null) {
                config.params = {}
            }

            if (token) {
                config.params['token'] = token
            }
            if (tmpSafeCode) {
                config.params['tmpSafeCode'] = tmpSafeCode
            }

        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

axios.interceptors.response.use(
    resp => {
        const code = resp.data.code;
        if ([-1, -2, -3].indexOf(code) !== -1) {
            if (code === -1) {
                window.localStorage.clear()
            }
            router.push('/')
        }
        return resp
    }
)

