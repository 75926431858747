import commonAuthRouter from "@/router/common-auth-router";

const router = {
    path: '/entry',
    name: 'entry',
    component: () => import('@/views/Empty.vue'),
    redirect: '/entry/auth',
    children: [
        {
            path: 'auth',
            name: 'auth',
            component: () => import('@/views/auth/Index.vue'),
            redirect: '/entry/auth/login',
            children: commonAuthRouter
        },
        {
            path: 'home',
            name: 'home',
            meta: {
                title: 'home'
            },
            component: () => import('@/views/Home.vue'),
        },
    ]
}
export default router