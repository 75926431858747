const commonAuthRouter = [
    {
        path: 'login',
        name: 'login',
        meta: {
            title: 'auth'
        },
        component: () => import('@/views/auth/Login.vue'),
    },
    {
        path: 'register',
        name: 'register',
        component: () => import('@/views/auth/Register.vue'),
    },
    {
        path: 'forget-pwd',
        name: 'forget-pwd',
        component: () => import('@/views/auth/ForgetPwd.vue'),
    },
    {
        path: 'new-pwd',
        name: 'new-pwd',
        component: () => import('@/views/auth/NewPwd.vue'),
    },
    {
        path: 'ok-action',
        name: 'ok-action',
        component: () => import('@/views/auth/OkAction.vue'),
    },
    {
        path: 'logout',
        name: 'logout',
        component: () => import('@/views/auth/Logout.vue'),
    },

]


export default commonAuthRouter