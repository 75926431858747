import commonAuthRouter from "@/router/common-auth-router";

const router = {
    path: '/net-verification',
    name: 'net-verification',
    component: () => import('@/views/netVerification/Index.vue'),
    redirect: '/net-verification/auth',
    children: [
        {
            path: 'auth',
            name: 'auth',
            component: () => import('@/views/netVerification/Auth.vue'),
            redirect: '/net-verification/auth/login',
            children: commonAuthRouter
        },
        {
            path: 'home',
            name: 'home',
            component: () => import('@/views/netVerification/Home.vue'),
            redirect: '/net-verification/home/main',
            children: [
                {
                    path: 'main',
                    component: () => import('@/views/netVerification/dashboard/Main.vue')
                },
                {
                    path: 'app',
                    component: () => import('@/views/netVerification/dashboard/App.vue')
                },
                {
                    path: 'app-version',
                    component: () => import('@/views/netVerification/dashboard/AppVersion.vue')
                },
                {
                    path: 'app-notice',
                    component: () => import('@/views/netVerification/dashboard/AppNotice.vue')
                },
                {
                    path: 'resource',
                    component: () => import('@/views/netVerification/dashboard/Resource.vue')
                },
                {
                    path: 'app-user',
                    component: () => import('@/views/netVerification/dashboard/User.vue')
                },
                {
                    path: 'app-agent',
                    component: () => import('@/views/netVerification/dashboard/User.vue')
                },
                {
                    path: 'pay-card',
                    component: () => import('@/views/netVerification/dashboard/PayCard.vue')
                },
                {
                    path: 'pay-card-type',
                    component: () => import('@/views/netVerification/dashboard/PayCardType.vue')
                },
                {
                    path: 'function-compute',
                    component: () => import('@/views/netVerification/dashboard/FunctionCompute.vue')
                },

            ]

        },

    ]
}
export default router