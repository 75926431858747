import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthRouter from "@/router/auth-router";
import StoreLakeRouter from "@/router/store-lake-router";
import ZctRouter from "@/router/zct-router";
import SoftwareAuth from "@/router/net-verification-router";
import CatKitty from "@/router/cat-kitty";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('@/views/Index.vue')
    },
    AuthRouter,
    StoreLakeRouter,
    SoftwareAuth,
    CatKitty
]


const router = new VueRouter({
    mode: 'history', base: process.env.BASE_URL, routes
})


router.beforeEach((to, from, next) => {
    const token = window.localStorage.getItem('token')
    if (token && to.meta.title === 'auth') {
        next(to.matched[0].path + '/home')
    } else if (!token && to.meta.title === 'home') {
        next(to.matched[0].path + '/auth')
    } else {
        next()
    }
})

// 解决Redirected when going from "path1" to "path2" via a navigation guard.问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

export default router
